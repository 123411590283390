'use client';

import React, { JSX, useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import YupProvider from '@th-common/components/common/YupProvider';
import MuiXLicense from '@th-common/components/material/MuiXLicense';
import AbilityProvider from '@th-common/providers/ability.provider';
import EnvironmentProvider from '@th-common/providers/environment.provider';
import store from '@th-common/store/store';
import { palette } from '@th-common/theme/palette';
import ThemeRegistry from '@th-common/theme/ThemeRegistry';

interface IProps {
  children: React.ReactNode;
}

function Providers({ children }: IProps): JSX.Element {
  useEffect(() => {
    // Set CSS variables on the document's root
    const root = document.documentElement;
    Object.entries(palette).forEach(([name, value]) => {
      root.style.setProperty(`--color-${name}`, value.main);
    });
  }, []);

  return (
    <ThemeRegistry options={{ key: 'mui-theme' }}>
      <EnvironmentProvider>
        <ReduxProvider store={store}>
          <AbilityProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {children}
              <MuiXLicense />
              <YupProvider />
            </LocalizationProvider>
          </AbilityProvider>
        </ReduxProvider>
      </EnvironmentProvider>
    </ThemeRegistry>
  );
}

export default Providers;
