'use client';

// This is a client component

// Import the next/image component
import React, { CSSProperties, JSX } from 'react';
import { ReactSVG } from 'react-svg';

import spritePath from '../../public/sprite/sprite.svg';

const styles: CSSProperties = {
  height: 0,
  opacity: 0,
  display: 'none',
};

const SvgSprite: React.FC = (): JSX.Element => (
  <div style={styles}>
    <ReactSVG
      src={spritePath.src}
      beforeInjection={(svg) => {
        // Get all child elements of the SVGSVGElement
        const elementsWithId = svg.querySelectorAll('[id]');
        const elementsWithFill = svg.querySelectorAll('[fill]');

        // Iterate over the elements and update the id attribute
        elementsWithId.forEach((element) => {
          element.setAttribute('id', `icon-${element.id}`);
        });

        // Iterate over the elements and update the fill attribute which uses the url() function with the id
        elementsWithFill.forEach((element) => {
          const fill = element.getAttribute('fill');
          if (fill && fill.includes('url(#')) {
            element.setAttribute('fill', fill.replace(/url\(#/g, 'url(#icon-'));
          }
        });

        return svg;
      }}
    />
  </div>
);

export default SvgSprite;
