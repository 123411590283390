import { videoArchiveApiEndpoints } from '@th-common/api/video-archive.api';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { IVideoArchiveItem, IVideoArchiveSearchRequest } from '@th-common/interfaces/video-archive.interface';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getVideoArchiveList: builder.query<IPagedResult<IVideoArchiveItem>, IVideoArchiveSearchRequest>(
      videoArchiveApiEndpoints.getVideoArchiveList,
    ),
  }),
});

export const { useGetVideoArchiveListQuery } = api;
