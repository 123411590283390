import { createSelector } from '@reduxjs/toolkit';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import {
  IVideoArchiveFiltersFormValues,
  IVideoArchiveItem,
  IVideoArchiveSearchRequest, TVideoArchiveFiltered,
} from '@th-common/interfaces/video-archive.interface';

import { RootState } from '../store';

import { defaultFilters } from './slice';

function convertFilterToFormValues(filters: TVideoArchiveFiltered): IVideoArchiveFiltersFormValues {
  return {
    userKey: filters.userKey === null ? 'All' : filters.userKey,
    storageType: filters.storageType === null ? 'All' : filters.storageType,
    downloadJobType: filters.downloadJobType === null ? 'All' : filters.downloadJobType,
    orderByDirection: filters.orderBy[0].ascending ? 'asc' : 'desc',
  };
}

export const selectors = {
  videoArchiveList: (state: RootState): IVideoArchiveItem[] => state.videoArchive.videoArchiveList,
  searchRequest: (state: RootState): IVideoArchiveSearchRequest => state.videoArchive.searchRequest,
  lastTimeUpdated: (state: RootState): string => state.videoArchive.lastTimeUpdated,
  dataListLoading: (state: RootState): boolean => state.videoArchive.dataListLoading,
  pagination: (state: RootState): Omit<IPagedResult<IVideoArchiveItem>, 'items'> => state.videoArchive.pagination,
  pageSize: (state: RootState): number => state.videoArchive.searchRequest.pageSize,
  defaultFilters: createSelector(
    (): TVideoArchiveFiltered => defaultFilters,
    (filters: TVideoArchiveFiltered): IVideoArchiveFiltersFormValues => convertFilterToFormValues(filters),
  ),
  currentFilters: createSelector(
    (state: RootState) => state.videoArchive.searchRequest,
    (searchRequest: IVideoArchiveSearchRequest): IVideoArchiveFiltersFormValues => {
      return convertFilterToFormValues(searchRequest);
    },
  ),
};
