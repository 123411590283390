import { config } from '@th-common/utils/config';

export enum TPermissionAbilitySubject {
  ByDefault = 'byDefault',
  DashboardPage = 'dashboardPage',
  FleetStatusPage = 'fleetStatusPage',
  ChangePasswordPage = 'changePasswordPage',
  Jobs = 'jobs',
  VideoQueryJob = 'videoQueryJob',
  GeographicalQueryJob = 'geographicalQueryJob',
  RidershipValidationJob = 'ridershipValidationJob',
  StatusUpdateJob = 'statusUpdateJob',
  LogbookUpdateJob = 'logbookUpdateJob',
  EventDownloadJob = 'eventDownloadJob',
  TimeSyncJob = 'timeSyncJob',
  SyncConfigurationJob = 'syncConfigurationJob',
  FirmwareUpdateJob = 'firmwareUpdateJob',
  CameraVerificationJob = 'cameraVerificationJob',
  Database = 'database',
  AddDeviceCellular = 'addDeviceCellular',
  VideoPlayback = 'videoPlayback',
  Service = 'service',
  ManageUsers = 'users',
  ReferenceImage = 'referenceImage',
  VerificationImage = 'verificationImage',
  NetworkScope = 'NetworkScope',
  DownloadNetworkScope = 'DownloadNetworkScope',
  DownloadJobNetworkScope = 'downloadJobNetworkScope',
  PushConfiguration = 'pushConfiguration',
  ShowDebugInfo = 'showDebugInfo',
  VideoStorageManagement = 'videoStorageManagement',
  DeleteVideoArchive = 'deleteVideoArchive',
}

export enum TPermissionAbilityAction {
  View = 'view',
  Manage = 'managing', // changed to 'managing', because 'manage' is a special permission that allows all actions
  Set = 'set',
  Override = 'override',
  AcceptReject = 'acceptReject',
  Delete = 'delete',
}

export const menuItemToPermissionMap = {
  [config.routes.homePageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.DashboardPage,
  },
  [config.routes.fleetsPageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.FleetStatusPage,
  },
  [config.routes.jobsPageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.Jobs,
  },
  [config.routes.videoPlaybackPageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.ByDefault,
  },
  [config.routes.videoPlayerPageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.ByDefault,
  },
  [config.routes.videoQueriesPageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.VideoQueryJob,
  },
  [config.routes.geographicalQueriesPageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.GeographicalQueryJob,
  },
  [config.routes.ridershipQueriesPageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.RidershipValidationJob,
  },
  [config.routes.markedVideoPageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.VideoQueryJob,
  },
  [config.routes.userPreferencesPageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.ByDefault,
  },
  [config.routes.changePasswordPageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.ChangePasswordPage,
  },
  [config.routes.vehicleDatabasePageUrl]: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.Database,
  },
  Logout: {
    action: TPermissionAbilityAction.View,
    subject: TPermissionAbilitySubject.ByDefault,
  },
};
