/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { dateKindToDate } from '@th-common/components/form/DateKindPicker/index.helpers';
import { TDateKind } from '@th-common/enums/date-kind.enum';
import { TCombinedFiltersType } from '@th-common/enums/status/operational-status.enum';
import { IMarkedVideo, IMarkedVideoSearchRequest } from '@th-common/interfaces/marked-video';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { DateTimeUtils } from '@th-common/utils';
import dayjs from 'dayjs';

import { api } from './api';

export interface IMarkedVideoState {
  combinedFilter: TCombinedFiltersType;
  dataListLoading: boolean;
  searchRequest: IMarkedVideoSearchRequest;
  pagination: Omit<IPagedResult<IMarkedVideo>, 'items'>;
  lastTimeUpdated: string;
  markedVideos: IMarkedVideo[];
}

const [defaultFromDate, defaultToDate] = dateKindToDate(TDateKind.AllTime);

const initialState: IMarkedVideoState = {
  combinedFilter: 'All',
  dataListLoading: false,
  searchRequest: {
    page: 1,
    pageSize: 36,
    searches: [],
    searchAsOneWord: true,
    orderBy: [
      {
        fieldName: 'createTime', // TODO DiLi changeTo markedTime
        ascending: false,
      },
    ],
    fromDate: DateTimeUtils.onlyDateRequest(defaultFromDate),
    toDate: DateTimeUtils.onlyDateRequest(defaultToDate),
  },
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  lastTimeUpdated: dayjs().toISOString(),
  markedVideos: [],
};
export const slice = createSlice({
  name: 'markedVideo',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    setDataListLoading: (state, { payload }: { payload: boolean }) => {
      state.dataListLoading = payload;
    },
    search: (state, { payload }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        searches: payload ? [payload] : [],
      };
    },
    setPage: (state, { payload }: { payload: number }) => {
      state.searchRequest.page = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IMarkedVideoState>) => {
    builder
      .addMatcher(api.endpoints.getMarkedVideos.matchFulfilled, (state, { payload }) => {
        const { items, ...pagination } = payload;
        state.markedVideos = items || [];
        state.pagination = pagination;
        state.lastTimeUpdated = dayjs().toISOString();
      });
  },
});

export default slice.reducer;
