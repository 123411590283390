import { IIdentity } from '@th-common/interfaces/lookup';
import { IPermission } from '@th-common/interfaces/navigation-menu';

import { TPermissionAbilityAction, TPermissionAbilitySubject } from '../permission-ability.enum';

export enum TJobType {
  /// <summary>
  /// Status update
  /// </summary>
  StatusUpdate = 0,
  /// <summary>
  /// Logbook update
  /// </summary>
  LogbookUpdate = 1,
  /// <summary>
  /// Event download
  /// </summary>
  EventDownload = 2,
  /// <summary>
  /// Time synchronization
  /// </summary>
  TimeSync = 3,
  /// <summary>
  /// Video query
  /// </summary>
  UserDownload = 4,
  /// <summary>
  /// Initial audit
  /// </summary>
  InitialAudit = 5,
  /// <summary>
  /// Sync configuration
  /// </summary>
  ConfigUpdate = 6,
  /// <summary>
  /// Firmware Update
  /// </summary>
  FirmwareUpdate = 7,
  /// <summary>
  /// Camera Verification
  /// </summary>
  CameraVerification = 8,
  /// <summary>
  /// Push configuration
  /// </summary>
  ConfigPush = 9,
  /// <summary>
  /// Geographical query
  /// </summary>
  GeographicalQuery = 10,
  /// <summary>
  /// Ridership query
  /// </summary>
  RidershipQuery = 11,
}

export const jobTypeToLabel: Record<TJobType, string> = {
  [TJobType.StatusUpdate]: 'Status Update',
  [TJobType.LogbookUpdate]: 'Logbook Update',
  [TJobType.EventDownload]: 'Event Download',
  [TJobType.TimeSync]: 'Time Synchronization',
  [TJobType.UserDownload]: 'Video Query',
  [TJobType.InitialAudit]: 'Initial Audit',
  [TJobType.ConfigUpdate]: 'Sync Configuration',
  [TJobType.FirmwareUpdate]: 'Firmware Update',
  [TJobType.CameraVerification]: 'Camera Verification',
  [TJobType.ConfigPush]: 'Push Configuration',
  [TJobType.GeographicalQuery]: 'Geographical Query',
  [TJobType.RidershipQuery]: 'Ridership Validation',
};

export const jobTypeToIcon: Record<TJobType, string> = {
  [TJobType.StatusUpdate]: 'help-solid',
  [TJobType.LogbookUpdate]: 'import-contacts',
  [TJobType.EventDownload]: 'event-download',
  [TJobType.TimeSync]: 'alarm',
  [TJobType.UserDownload]: 'download',
  [TJobType.InitialAudit]: 'assignment', // no icon
  [TJobType.ConfigUpdate]: 'construction',
  [TJobType.FirmwareUpdate]: 'network-intelligence-update',
  [TJobType.CameraVerification]: 'videocam',
  [TJobType.ConfigPush]: 'tv-options-input-settings',
  [TJobType.GeographicalQuery]: 'explorer',
  [TJobType.RidershipQuery]: 'confirmation-number',
};

export const jobTypeToFilterList: IIdentity<TJobType>[] = [
  { id: TJobType.StatusUpdate, name: jobTypeToLabel[TJobType.StatusUpdate] },
  { id: TJobType.LogbookUpdate, name: jobTypeToLabel[TJobType.LogbookUpdate] },
  { id: TJobType.EventDownload, name: jobTypeToLabel[TJobType.EventDownload] },
  { id: TJobType.TimeSync, name: jobTypeToLabel[TJobType.TimeSync] },
  { id: TJobType.UserDownload, name: jobTypeToLabel[TJobType.UserDownload] },
  // { id: TJobType.InitialAudit, name: jobTypeToLabel[TJobType.InitialAudit] },
  { id: TJobType.ConfigUpdate, name: jobTypeToLabel[TJobType.ConfigUpdate] },
  { id: TJobType.FirmwareUpdate, name: jobTypeToLabel[TJobType.FirmwareUpdate] },
  { id: TJobType.CameraVerification, name: jobTypeToLabel[TJobType.CameraVerification] },
  { id: TJobType.ConfigPush, name: jobTypeToLabel[TJobType.ConfigPush] },
  // { id: TJobType.GeographicalQuery, name: jobTypeToLabel[TJobType.GeographicalQuery] },
];

export const jobTypeToManagePermissionMap: Record<TJobType, IPermission> = {
  [TJobType.StatusUpdate]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.StatusUpdateJob,
  },
  [TJobType.LogbookUpdate]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.LogbookUpdateJob,
  },
  [TJobType.EventDownload]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.EventDownloadJob,
  },
  [TJobType.TimeSync]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.TimeSyncJob,
  },
  [TJobType.UserDownload]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.VideoQueryJob,
  },
  [TJobType.InitialAudit]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.ByDefault,
  },
  [TJobType.ConfigUpdate]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.SyncConfigurationJob,
  },
  [TJobType.FirmwareUpdate]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.FirmwareUpdateJob,
  },
  [TJobType.CameraVerification]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.CameraVerificationJob,
  },
  [TJobType.ConfigPush]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.PushConfiguration,
  },
  [TJobType.GeographicalQuery]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.GeographicalQueryJob,
  },
  [TJobType.RidershipQuery]: {
    action: TPermissionAbilityAction.Manage,
    subject: TPermissionAbilitySubject.RidershipValidationJob,
  },
};

export const jobWizardTypes = [
  {
    id: TJobType.StatusUpdate,
    iconName: 'help-solid',
    description: 'A status update job requests the current status from a device.',
  },
  {
    id: TJobType.LogbookUpdate,
    iconName: 'import-contacts',
    description: 'A logbook update requests new logbook entries from a device.',
  },
  {
    id: TJobType.EventDownload,
    iconName: 'event-download',
    description: 'An event download will initiate downloading all current events from a device.',
  },
  {
    id: TJobType.TimeSync,
    iconName: 'alarm',
    description: 'A time synchronization job will sync the device time with the computer time. '
    + 'If the device time is ahead of the current time, all future data will be deleted!',
  },
  {
    id: TJobType.UserDownload,
    iconName: 'download-2',
    description: 'A video query will download a specified amount of data from a device.',
  },
  {
    id: TJobType.ConfigUpdate,
    iconName: 'construction',
    description: 'Sync the device configuration.',
  },
  {
    id: TJobType.FirmwareUpdate,
    iconName: 'network-intelligence-update',
    description: 'Perform a firmware update when the device connects to the server. '
    + 'This will require a reboot of the device once the firmware has been transferred.',
  },
  {
    id: TJobType.CameraVerification,
    iconName: 'videocam',
    description: 'A camera verification job collects all cameras of a recorder and stores camera images for camera verification.',
  },
  {
    id: TJobType.ConfigPush,
    iconName: 'tv-options-input-settings',
    description: 'This job will update the device configuration with a template configuration.',
  },
  {
    id: TJobType.GeographicalQuery,
    iconName: 'explore-filled',
    description: 'Download a specified amount of data from a device that has navigated through a specific area.',
  },
  {
    id: TJobType.RidershipQuery,
    iconName: 'confirmation-number-filled',
    description: 'This job will validate the riders who ride the bus.',
  },
];
